
































import Vue from 'vue'

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
export default Vue.extend({
  name: 'App',
  data() {
    return {
      contentWidth: 0,
      contentHeight: 0,
      msgOK: '',
      afficherOK: false,
      msgERR: '',
      afficherERR: false,
      utilisateur: localStorage.getItem('maintenance_utilisateur2'),
      items: [{ title: 'Maintenance<br><small>intervention</small>', icon: 'mdi-wrench-outline', color: 'green', path: '/maintenance' }],
      onglet: '',
      uuid: localStorage.getItem('maintenance-uuid')
    }
  },
  methods: {
    messageOK(msg: any) {
      this.msgOK = msg
      this.afficherOK = true
    },
    messageERR(msg: any) {
      this.msgERR = msg
      this.afficherERR = true
    },
    onResize() {
      this.contentWidth = window.innerWidth
      this.contentHeight = window.innerHeight - 48
      this.$store.commit('majContentHeight', { width: this.contentWidth, height: this.contentHeight })
    },
    envoyerAuServeur() {}
  },
  mounted() {
    if (!localStorage.getItem('maintenance-uuid')) {
      this.uuid = uuidv4()
      localStorage.setItem('maintenance-uuid', this.uuid)
    }
    this.onglet = ''
    this.$store.dispatch('chargerTables')
  }
})
