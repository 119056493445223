export function stringFromDate(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d
  return `${y}-${mmo}-${dd}`
}
export function stringFromDatetime(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? '0' : '') + h
  const mmn = (mn < 10 ? '0' : '') + mn

  return `${y}-${mmo}-${dd} ${hh}:${mmn}`
}
export function dateFromString(stdate: string /**yyyy-mm-dd */): Date {
  if (stdate == null) return new Date()
  const [year, month, day] = stdate.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}
export function padEnd(str: string, length: number, char = '0'): string {
  return str + char.repeat(Math.max(0, length - str.length))
}

export function cleControle(): string {
  // la date est codée en base 31
  const dte = new Date()
  dte.setHours(12)
  const n = ((dte.getMonth() + 1) * 100 + dte.getDate()) * 10000 + dte.getFullYear()
  const cle = n.toString(31)
  return cle
}
/* export function verifConnexion(vm: Vue): void {
  let ut_id = vm.$store.state.ut_id
  if (ut_id == 0) {
    const ident = localStorage.getItem('maintenance_identifiant')
    if (ident && ident > '') {
      ut_id = localStorage.getItem('maintenance_identifiant')
      //re-connexion
      vm.$store.commit('login', ut_id)
    } else {
      vm.$router.replace('/login')
    }
  }
} */
import { openDB, IDBPDatabase } from 'idb'

export function dbMaintenance(): Promise<IDBPDatabase<unknown>> {
  const db = openDB('maintenance', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('salaries')) {
        db.createObjectStore('salaries', { keyPath: 'ut_id' }) //
      }
      if (!db.objectStoreNames.contains('machines')) {
        db.createObjectStore('machines', { keyPath: 'mac_id' })
      }
      if (!db.objectStoreNames.contains('maintenance')) {
        const rdg = db.createObjectStore('maintenance', { keyPath: 'mt_id' })
        rdg.createIndex('date', 'mt_date')
        rdg.createIndex('situation', 'mt_statut')
      }
    }
  })
  return db
}
