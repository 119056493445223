import Vue from 'vue'
import Vuex from 'vuex'
import { IUtilisateur, Decrassage, Machine, Produit } from './types'
import axios from 'axios'
import { cleControle, stringFromDate, dbMaintenance } from '../plugins/variables'

const debug = process.env.NODE_ENV === 'development'
const jour = stringFromDate(new Date())

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jour: jour, // string
    h24: true,
    version: '2021-003',
    ajaxurl: debug ? 'http://localhost/oxytec/maintenance/php/' : './php/',
    contentWidth: 0,
    contentHeight: 0,
    ut_id: 0,
    utilisateur: '',
    site: 'BELVAL',
    site_id: 1,
    salaries: [] as IUtilisateur[],
    machines: [] as Machine[],
    produits: [] as Produit[],
    decrassages: [] as Decrassage[]
  },
  mutations: {
    majJour(state, ndate) {
      state.jour = ndate
    },
    majH24(state, h24) {
      state.h24 = h24
      if (h24) {
        const date = new Date()
        state.jour = stringFromDate(date)
      }
    },
    async majMachines(state, machines) {
      state.machines = machines.slice()
      //sauvegarde locale indexDB
      const db = await dbMaintenance()
      const store = db.transaction('machines', 'readwrite').store
      store.clear()
      for (const mac of machines) {
        store.put(mac)
      }
      db.close()
    },
    async majSalaries(state, salaries) {
      state.salaries = salaries.slice()
      //sauvegarde locale indexDB
      const db = await dbMaintenance()
      const store = db.transaction('salaries', 'readwrite').store
      store.clear()
      for (const sal of salaries) {
        store.put(sal)
      }
      db.close()
    },
    login(state, ut_id) {
      state.ut_id = ut_id
    },
    majContentHeight(state, payload) {
      state.contentWidth = payload.width
      state.contentHeight = payload.height
    }
  },
  actions: {
    chargerTables(context) {
      const url = context.state.ajaxurl + 'ajax_appli.php'
      axios
        .get(url, {
          params: {
            cle: cleControle(),
            action: 'TABLES'
          }
        })
        .then((response) => {
          if (response.data.machines) {
            context.commit('majMachines', response.data.machines)
          }
          if (response.data.salaries) {
            context.commit('majSalaries', response.data.salaries)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  modules: {},
  getters: {
    listeMachines: (state) => {
      // retourne un tableau de machines
      return (chantierID: number, jour1: string, jour2: string) => {
        const machines = []
        for (let n = 0; n < state.machines.length; n++) {
          const machine = state.machines[n]
          //if (machine.ch_id !== chantierID) continue
          if (machine.mac_datesortie && machine.mac_datesortie < jour1) continue
          if (machine.mac_dateentree > jour2) continue
          machines.push(machine)
        }
        return machines
      }
    }
  }
})
