import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './css/table.sass'

Vue.config.productionTip = false

Vue.filter('stTypeacier', function (value: string) {
  //CA1=CA avant inox, QI=Quasinox, IN=Inox, CA2=CA après inox
  if (!value) return ''
  switch (value) {
    case 'CA1':
      return 'CA avant inox'
      break
    case 'CA2':
      return 'CA après inox'
      break
    case 'IN':
      return 'Inox'
      break
    case 'QI':
      return 'Quasinox'
      break
  }
  return value
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
